* {
  margin: 0;
  padding: 0;
}

.promo-section {
  width: 100vw;
  height: 55vh;
  position: relative;
  /* margin-top: 80px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: #fff;

  margin: 90px 0px 10px 0px;
}
.promo-hero {
  width: 100%;
  height: 100%;
  /* padding: 0px 10px 0 10px; */
  /* border-radius: 52px; */
}
.img-wrapper {
  height: 100%;
  flex: 33%;
}

.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.promo-section .promo-content {
  z-index: 777;
  color: #fff;
  width: 100%;
  height: 100%;
  /* padding-top: 50px; */
  padding-left: 200px;
  position: absolute;
  display: none;
}
.promo-section .promo-content.active {
  /* display: block; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.promo-title {
  font-size: 36px;
}
.promo-subtitle {
  font-size: 24px;
}
.promo-section .promo-content button {
  background: #81f7b6;
  padding: 15px 35px;
  color: #251119;
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  border: none;
  border-radius: 10px;
}
.promo-content button:hover {
  color: #81f7b6;
  background: #9225fe;
  transition: ease-in-out 0.3s;
}

.promo-section .promo-content .promo-title {
  font-size: 2em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 5px;
  /* line-height: 75px; */
  /* margin-bottom: 40px; */
}

.promo-slide {
  position: absolute;
  width: 100%;
  clip-path: circle(0% at 0 50%);
}

.promo-slide.active {
  clip-path: circle(150% at 0 50%);
  transition: 2s ease;
  transition-property: clip-path;
}
