header {
  z-index: 888;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* padding: 15px 10px; */
  transition: 0.5s ease;
  background-color: #81f7b6;
}

header .brand {
  color: #000;
  font-size: 1.5em;
  font-weight: 700;
  /* text-transform: uppercase; */
  text-decoration: none;
  font-family: 'AlishaFree';
}

.brand:hover {
  color: #9225fe;
}

header .navigation {
  position: relative;
}

header .navigation .navigation-items a {
  position: relative;
  color: black;
  font-size: 1em;
  font-weight: 400;
  text-decoration: none;
  margin-left: 30px;
  transition: 0.3s ease;
}

header .navigation .navigation-items a:hover {
  color: #9225fe;
  transition: all 0.2s ease-out;
}

header .navigation .navigation-items a:before {
  content: '';
  position: absolute;
  background: #9225fe;
  width: 0;
  height: 3px;
  bottom: 0;
  left: 0;
  transition: 0.3s ease;
}

header .navigation .navigation-items a:hover:before {
  width: 100%;
}

.socials {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  text-align: center;
  margin: 0;
  /* margin-right: 22px; */

  /* @media screen and (max-width: 760px) {
    display: none;
  } */
}

.social-items {
  height: 100%;
}

.social-items a {
  color: #251119;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.social-items a:hover {
  color: #9225fe;
}

.menu-btn {
  display: none;
}

@media screen and (max-width: 990px) {
  nav {
    transition: 0.8s all ease;
  }

  .navigation {
    display: none;
  }
  .socials {
    display: none;
  }
  .menu-btn {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    cursor: pointer;
    color: #000;
  }
  .menuBars {
    font-size: 2rem;
  }
}

@media screen and (max-width: 672px) {
  .menu-btn {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    cursor: pointer;
    color: #000;
  }
  .menuBars {
    font-size: 2rem;
  }
}
