.home {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000;
  /* margin-top: 80px; */
;
}
.hero-container {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
}

.home .content {
  z-index: 777;
  color: #fff;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-left: 50px;
  position: absolute;
  display: none;
}

.home .content.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home .content h1 {
  font-size: 4em;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 75px;
  margin-bottom: 40px;
}

.home .content h1 span {
  font-size: 1.2em;
  font-weight: 600;
}

.home .content p {
  margin-bottom: 65px;
  width: 600px;
}

.home .content button {
  background: #81f7b6;
  padding: 15px 35px;
  color: #251119;
  font-size: 1.1em;
  font-weight: 500;
  text-decoration: none;
  border: none;
  border-radius: 10px;
}
.content button:hover {
  color: #81f7b6;
  background: #9225fe;
  transition: ease-in-out 0.3s;
}

.home video {
  z-index: 000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-slide {
  position: absolute;
  width: 100%;
  clip-path: circle(0% at 0 50%);
}

.video-slide.active {
  clip-path: circle(150% at 0 50%);
  transition: 2s ease;
  transition-property: clip-path;
}

@media screen and (max-width: 672) {
  .home .content h1 {
    font-size: 0.5em;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .home .content h1 span {
    font-size: 0.2em;
  }
}

@media screen and (max-width: 990) {
  .home .content h1 {
    font-size: 2em;
    line-height: 50px;
    margin-bottom: 20px;
  }
  .home .content h1 span {
    font-size: 0.4em;
    font-weight: 600;
  }
}
