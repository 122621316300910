@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.shade {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 999;
  cursor: pointer;
  user-select: none;
}
.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 999;
  cursor: pointer;
  user-select: none;
}

.slider-navigation {
  width: fit-content;
  height: fit-content;
  position: absolute;
  bottom: 120px;
  display: flex;
  transform: translateY(80px);
  z-index: 887;
}

.slider-navigation .nav-btn {
  width: 12px;
  height: 12px;
  background: #81f7b6;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
  transition: 0.3s ease;
}

.slider-navigation .nav-btn.active {
  background: #9225fe;
}

.slider-navigation .nav-btn:not(:last-child) {
  margin-right: 20px;
}

.slider-navigation .nav-btn:hover {
  transform: scale(1.2);
}

/* @media (max-width: 672px) {

  section {
    padding: 0px 48px;
  }
  section,
  h1,
  p {
    font-size: 0.8rem;
  }

  .footer-link-items {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
  .gallery {
    margin-top: 120px;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
} */

/* @media (max-width: 990px) {
  header {
    width: 100vw;
    min-width: 480px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0px 24px;
  }

  section {
    padding: 100px 20px;
    min-width: 480px;
  }

  header .navigation {
    display: none;
  }

  header .navigation.active {
    position: fixed;
    width: 100%;

    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(1, 1, 1, 0.5);
  }

  header .navigation .navigation-items a {
    color: #222;
    font-size: 1.2em;
    margin: 0px 20px;
  }

  header .navigation .navigation-items a:before {
    background: #222;
    height: 5px;
  }

  header .navigation.active .navigation-items {
    background: #fff;
    width: 600px;
    max-width: 600px;
    margin: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 5px 25px rgb(1 1 1 / 20%);
  }

  .menu-btn {
    position: sticky;
    display: block;
    background-position: right;
    transition: 0.3s ease;
    font-size: 2rem;
    padding: 5px;
    color: #251119;
    cursor: pointer;
  }

  .menu-btn.active {
    z-index: 999;
    background: url(close.png)no-repeat;
    background-size: 25px;
    background-position: center;
    transition: 0.3s ease;
  }

  .socials {
    display: none;
  }

  .social-items {
    display: none;
  }
  .footer-links-container {
    padding-top: 32px;
  }
  .footer-links-wrapper {
    flex-direction: column;
  }
  .social-media-wrapper {
    flex-direction: column;
  }
  .gallery {
    margin-top: 120px;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
} */

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* .navigation {
    display: none;
  } */
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}

/* iPhone 4 - 5s ----------- */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  /* Styles */
}

/* iPhone 6 ----------- */
@media only screen and (max-device-width: 667px),
  only screen and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6+ ----------- */
@media only screen and (min-device-width: 414px),
  only screen and (-webkit-device-pixel-ratio: 3) {
  /*** You've spent way too much on a phone ***/
}

/* Samsung Galaxy S7 Edge ----------- */
@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-resolution: 192dpi) and (max-width: 640px) {
  /* Styles */
}

